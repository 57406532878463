import React from 'react'

function Loader ({ text, text_color }) {
  return (
    <div className='d-flex justify-content-center align-items-center w-100'>
      <div className={`spinner-loader-border ${text_color || 'text-primary'}`} role='status' />
      {text && <div className={`mt-n6 ${text_color || 'text-primary'}`}>{text}</div>}
    </div>
  )
}

export default Loader
