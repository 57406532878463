import React, { Fragment } from 'react'
import { imagePricing } from 'utils/constants'
import PricingCards from './PricingCard'
import Switch from './Switch'

export default function Image ({
  summary,
  stripe,
  onPlanSelection,
  orgData,
  openChat,
  toggle,
  planType,
  handleYearlyPriceToggle,
  coupon,
  userData,
  onCloseModal
}) {
  return (
    <>
      {!summary ? (
        <div className='d-flex justify-content-between align-items-start'>
          <div className='d-flex gap-2'>
            <p className='text-white-60'>{imagePricing.description}</p>
            {coupon?.value
              ? (
                <span className='text-primary'>
                  ({coupon?.value}
                  {coupon?.discount_type === 'percent' ? '%' : '$'} discount will
                  be applied on the total price)
                </span>
                )
              : (
                <></>
                )}
          </div>
          <div className='image_yearly_toggle'>
            {/* // ## pricing modal swtich */}
            <Switch
              summary={summary}
              stripe={stripe}
              toggle={toggle}
              handleYearlyPriceToggle={handleYearlyPriceToggle}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* ## IMAGE PRICING CARDS ## */}
      <div className='row'>
        {imagePricing.plans.map((price, i) => {
          return (
            <PricingCards
              key={i}
              columnClass='col-lg-3'
              price={price}
              onPlanSelection={onPlanSelection}
              orgData={orgData}
              openChat={openChat}
              toggle={toggle}
              planType={planType}
              userData={userData}
              onCloseModal={onCloseModal}
            />
          )
        })}
        <div className='d-flex justify-content-between align-items-center'>
          {!summary && !stripe && (
            <span className='text-muted'>
              <a
                href='https://www.gumlet.com/pricing?tab=image'
                target='_blank'
                rel='noreferrer'
              >
                Learn more about our plans
                <i className='tio-arrow-forward ms-1' />
              </a>
            </span>
          )}
        </div>
      </div>
    </>
  )
}
