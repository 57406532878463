import React from 'react'
import { NavLink } from 'react-router-dom'
import { routePaths } from 'global/routePaths'
import { seo } from 'utils/constants'

const Page404 = () => {
  seo({
    title: 'Page Not Found - Gumlet'
  })
  const siteUrl = routePaths.home
  const host = window.location.host
  const url = siteUrl.replace('www.gumlet.com', host)
  return (
    <section
      className='pt-md-13 pb-11 pb-md-10'
      style={{ paddingTop: '150px' }}
    >
      <div className='container-lg'>
        <div className='row align-items-center justify-content-center'>
          <div className='col-lg-12 order-md-0 text-center'>
            <div className='position-relative mb-8'>
              <div className='text-center'>
                <img
                  className='img-fluid'
                  src='https://assets.gumlet.io/public/img/errors/4xx.svg'
                  alt='5xx error'
                  style={{ maxWidth: '35%' }}
                />
              </div>
            </div>
          </div>
          <div className='col-lg-10 col-md-10 col-12 order-md-1 text-center'>
            <h6 className='text-uppercase text-primary mb-6'>404 Not Found</h6>
            <h2 className='display-4 mb-5'>This page does not seem to exist</h2>
            <NavLink to={url} className='btn btn-primary lift'>
              Go back to Homepage
            </NavLink>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Page404
