export const engagement = [
  {
    value: 'views',
    label: 'Total Views',
    func: 'sum',
    desc: 'The total number of views that started during the selected time interval.'
  },
  {
    value: 'unique_views',
    label: 'Unique Users',
    func: 'sum',
    desc: 'Total number of unique viewers in given time frame.'
  },
  {
    value: 'playing_time',
    label: 'Playing Time',
    func: 'sum',
    desc: 'The total time in hours where viewers watched the video. This time excludes seek time, rebuffering time and the time users spent while the video was paused.'
  },
  {
    value: 'completion_percent_by_views',
    label: 'Playback Completed By Views',
    func: 'average',
    desc: 'Distribution of viewers based on the completion of playback before the viewer dropped off.'
  },
  {
    value: 'completion_percent',
    label: 'Playback Completed By Users',
    func: 'average',
    desc: 'Distribution of viewers based on the completion of playback before the viewer dropped off.'
  },
  { value: 'concurrent_users', label: 'Concurrent Users', func: 'average' }
]

export const timings_and_latencies = [
  {
    value: 'player_startup_time',
    label: 'Player Startup Time',
    func: 'average',
    desc: 'Time the player takes to initialise itself and is ready for further interaction with the viewer to be able to start the playback.'
  },
  {
    value: 'startup_time',
    label: 'Video Startup Time',
    func: 'average',
    desc: 'Time the player takes when the viewer clicks the play button for the first time to the time first frame of the video is shown to the viewer. In case of auto play this time is taken from the time when the player is instructed to play the video till the time the first frame is shown to the user.'
  },
  {
    value: 'seek_latency',
    label: 'Seek Latency',
    func: 'average',
    desc: 'Total amount of time spent waiting for playback to resume after the viewer seeks to a new location.'
  }
]

export const playback_metrics = [
  {
    value: 'rebuffer_percentage',
    label: 'Rebuffer Percentage',
    func: 'average',
    desc: 'Measure of volume of rebuffering occurring across all video playbacks. Rebuffer Percentage = buffering time * 100 / total view time'
  },
  {
    value: 'rebuffer_duration',
    label: 'Rebuffer Duration',
    func: 'average',
    desc: 'Total amount of time viewers spent waiting for the video to buffer.'
  },
  {
    value: 'rebuffer_count',
    label: 'Rebuffer Count',
    func: 'sum',
    desc: 'Number of rebuffering events that happen during the video view.'
  },
  {
    value: 'rebuffer_frequency',
    label: 'Rebuffer Frequency',
    func: 'average',
    desc: 'Measures how often rebuffering events happen.'
  },
  {
    value: 'playback_rate',
    label: 'Playback Rate',
    func: 'average',
    desc: 'Identifies when a viewer abandons the video because it is taking too long to load.'
  },
  {
    value: 'playback_failure_percentage',
    label: 'Playback Failed',
    func: 'average',
    desc: 'Percentage of viewers who experienced an error at any point during the playback.'
  }
]

export const video_quality = [
  {
    value: 'upscale_percentage',
    label: 'Upscaling',
    func: 'average',
    desc: 'Maximum Upscale Percentage at any point in time during a video view.'
  },
  {
    value: 'downscale_percentage',
    label: 'Bandwidth Wastage',
    func: 'average',
    desc: 'Maximum Downscale Percentage at any point in time during a video view'
  },
  {
    value: 'average_bitrate',
    label: 'Average Bitrate',
    func: 'average',
    desc: 'The average amount of data bring transferred for all playbacks in the selected timeframe.'
  }
]

export const eventDefaultOptions = [
  {
    label: 'Engagement',
    options: engagement
  },
  {
    label: 'Timings/Latencies',
    options: timings_and_latencies
  },
  {
    label: 'Playback Metrics',
    options: playback_metrics
  },
  {
    label: 'Video Quality',
    options: video_quality
  }
]

// breakdown values
export const device = [
  {
    label: 'Browser',
    value: 'meta_browser'
  },
  {
    label: 'Operating System',
    value: 'meta_operating_system'
  },
  {
    label: 'OS Version',
    value: 'meta_operating_system_version'
  },
  {
    label: 'Category',
    value: 'meta_device_category'
  },
  {
    label: 'Manufacturer',
    value: 'meta_device_manufacturer'
  },
  {
    label: 'Touchscreen',
    value: 'meta_device_is_touchscreen'
  },
  {
    label: 'Connection Type',
    value: 'meta_connection_type'
  },
  {
    label: 'Connection Speed',
    value: 'meta_connection_speed'
  },
  {
    label: 'Device Name',
    value: 'meta_device_name'
  },
  {
    label: 'Device Width',
    value: 'meta_device_display_width'
  },
  {
    label: 'Device Height',
    value: 'meta_device_display_height'
  }
]

export const geography = [
  // {
  //   label: "Continent",
  //   value: "meta_continent_code",
  // },
  {
    label: 'Country',
    value: 'meta_country'
  },
  {
    label: 'City',
    value: 'meta_city'
  },
  {
    label: 'Region',
    value: 'meta_region'
  }
]

export const player = [
  {
    label: 'Player Software',
    value: 'player_software'
  },
  {
    label: 'Player Software Version',
    value: 'player_software_version'
  },
  {
    label: 'Player Height',
    value: 'player_height_pixels'
  },
  {
    label: 'Player Width',
    value: 'player_width_pixels'
  },
  {
    label: 'Player Language Code',
    value: 'player_language_code'
  },
  {
    label: 'Player Name',
    value: 'player_name'
  },
  {
    label: 'Player Integration Version',
    value: 'player_integration_version'
  },
  {
    label: 'Page Url',
    value: 'meta_page_url'
  }
]

export const network = [
  {
    label: 'ASN',
    value: 'meta_asn'
  }
]

export const user = [
  {
    label: 'User ID',
    value: 'custom_user_id'
  },
  {
    label: 'User Name',
    value: 'user_name'
  },
  {
    label: 'User Email',
    value: 'user_email'
  },
  {
    label: 'User Phone Number',
    value: 'user_phone'
  },
  {
    label: 'User City',
    value: 'user_city'
  },
  {
    label: 'User State',
    value: 'user_state'
  },
  {
    label: 'User Country',
    value: 'user_country'
  },
  {
    label: 'User Zipcode',
    value: 'user_zipcode'
  }
]

export const video = [
  {
    label: 'Video ID',
    value: 'custom_video_id'
  },
  {
    label: 'Video Title',
    value: 'custom_video_title'
  },
  {
    label: 'Video URL',
    value: 'video_source_url'
  },
  {
    label: 'Video Hostname',
    value: 'video_source_hostname'
  },
  {
    label: 'Video Format',
    value: 'video_source_format'
  },
  {
    label: 'Video Variant',
    value: 'custom_video_variant'
  },
  {
    label: 'Video Variant Name',
    value: 'custom_video_variant_name'
  },
  {
    label: 'Video Producer',
    value: 'custom_video_producer'
  },
  {
    label: 'Video Series',
    value: 'custom_video_series'
  },
  {
    label: 'Video Language',
    value: 'custom_video_language'
  },
  {
    label: 'Video Encoding Variant',
    value: 'custom_encoding_variant'
  },
  {
    label: 'Video Content Type',
    value: 'custom_content_type'
  }
]

export const custom_data = [
  {
    label: 'Custom Data 1',
    value: 'custom_data_1'
  },
  {
    label: 'Custom Data 2',
    value: 'custom_data_2'
  },
  {
    label: 'Custom Data 3',
    value: 'custom_data_3'
  },
  {
    label: 'Custom Data 4',
    value: 'custom_data_4'
  },
  {
    label: 'Custom Data 5',
    value: 'custom_data_5'
  },
  {
    label: 'Custom Data 6',
    value: 'custom_data_6'
  },
  {
    label: 'Custom Data 7',
    value: 'custom_data_7'
  },
  {
    label: 'Custom Data 8',
    value: 'custom_data_8'
  },
  {
    label: 'Custom Data 9',
    value: 'custom_data_9'
  },
  {
    label: 'Custom Data 10',
    value: 'custom_data_10'
  }
]

export const breakDownDefaultOptions = [
  {
    label: 'Device',
    options: device
  },
  {
    label: 'Geography',
    options: geography
  },
  {
    label: 'Player',
    options: player
  },
  {
    label: 'Network',
    options: network
  },
  {
    label: 'User',
    options: user
  },
  {
    label: 'Video',
    options: video
  },
  {
    label: 'Custom Data',
    options: custom_data
  }
]

export const operatorOptions = [
  {
    shortlabel: '=',
    label: '= (is)',
    value: 'equals'
  },
  {
    shortlabel: '≠',
    label: '≠ (is not)',
    value: 'does not equal'
  },
  {
    shortlabel: '∋',
    label: '∋ (contains)',
    value: 'contains'
  },
  {
    shortlabel: '∌',
    label: '∌ (does not contain)',
    value: 'does not contain'
  },
  {
    shortlabel: 'is set',
    label: 'is set',
    value: 'is set'
  },
  {
    shortlabel: 'is not set',
    label: 'is not set',
    value: 'is not set'
  }
]
