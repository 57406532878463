export const COUNTRY_CODES = [
  {
    label: 'Andorra',
    value: 'Andorra',
    key: 'AD'
  },
  {
    label: 'United Arab Emirates',
    value: 'United Arab Emirates',
    key: 'AE'
  },
  {
    label: 'Afghanistan',
    value: 'Afghanistan',
    key: 'AF'
  },
  {
    label: 'Antigua and Barbuda',
    value: 'Antigua and Barbuda',
    key: 'AG'
  },
  {
    label: 'Anguilla',
    value: 'Anguilla',
    key: 'AI'
  },
  {
    label: 'Albania',
    value: 'Albania',
    key: 'AL'
  },
  {
    label: 'Armenia',
    value: 'Armenia',
    key: 'AM'
  },
  {
    label: 'Angola',
    value: 'Angola',
    key: 'AO'
  },
  {
    label: 'Antarctica',
    value: 'Antarctica',
    key: 'AQ'
  },
  {
    label: 'Argentina',
    value: 'Argentina',
    key: 'AR'
  },
  {
    label: 'American Samoa',
    value: 'American Samoa',
    key: 'AS'
  },
  {
    label: 'Austria',
    value: 'Austria',
    key: 'AT'
  },
  {
    label: 'Australia',
    value: 'Australia',
    key: 'AU'
  },
  {
    label: 'Aruba',
    value: 'Aruba',
    key: 'AW'
  },
  {
    label: 'Åland Islands',
    value: 'Åland Islands',
    key: 'AX'
  },
  {
    label: 'Azerbaijan',
    value: 'Azerbaijan',
    key: 'AZ'
  },
  {
    label: 'Bosnia and Herzegovina',
    value: 'Bosnia and Herzegovina',
    key: 'BA'
  },
  {
    label: 'Barbados',
    value: 'Barbados',
    key: 'BB'
  },
  {
    label: 'Bangladesh',
    value: 'Bangladesh',
    key: 'BD'
  },
  {
    label: 'Belgium',
    value: 'Belgium',
    key: 'BE'
  },
  {
    label: 'Burkina Faso',
    value: 'Burkina Faso',
    key: 'BF'
  },
  {
    label: 'Bulgaria',
    value: 'Bulgaria',
    key: 'BG'
  },
  {
    label: 'Bahrain',
    value: 'Bahrain',
    key: 'BH'
  },
  {
    label: 'Burundi',
    value: 'Burundi',
    key: 'BI'
  },
  {
    label: 'Benin',
    value: 'Benin',
    key: 'BJ'
  },
  {
    label: 'Saint Barthélemy',
    value: 'Saint Barthélemy',
    key: 'BL'
  },
  {
    label: 'Bermuda',
    value: 'Bermuda',
    key: 'BM'
  },
  {
    label: 'Brunei Darussalam',
    value: 'Brunei Darussalam',
    key: 'BN'
  },
  {
    label: 'Bolivia (Plurinational State of)',
    value: 'Bolivia (Plurinational State of)',
    key: 'BO'
  },
  {
    label: 'Bonaire, Sint Eustatius and Saba',
    value: 'Bonaire, Sint Eustatius and Saba',
    key: 'BQ'
  },
  {
    label: 'Brazil',
    value: 'Brazil',
    key: 'BR'
  },
  {
    label: 'Bahamas',
    value: 'Bahamas',
    key: 'BS'
  },
  {
    label: 'Bhutan',
    value: 'Bhutan',
    key: 'BT'
  },
  {
    label: 'Bouvet Island',
    value: 'Bouvet Island',
    key: 'BV'
  },
  {
    label: 'Botswana',
    value: 'Botswana',
    key: 'BW'
  },
  {
    label: 'Belarus',
    value: 'Belarus',
    key: 'BY'
  },
  {
    label: 'Belize',
    value: 'Belize',
    key: 'BZ'
  },
  {
    label: 'Canada',
    value: 'Canada',
    key: 'CA'
  },
  {
    label: 'Cocos (Keeling) Islands',
    value: 'Cocos (Keeling) Islands',
    key: 'CC'
  },
  {
    label: 'Congo, Democratic Republic of the',
    value: 'Congo, Democratic Republic of the',
    key: 'CD'
  },
  {
    label: 'Central African Republic',
    value: 'Central African Republic',
    key: 'CF'
  },
  {
    label: 'Congo',
    value: 'Congo',
    key: 'CG'
  },
  {
    label: 'Switzerland',
    value: 'Switzerland',
    key: 'CH'
  },
  {
    label: "Côte d'Ivoire",
    value: "Côte d'Ivoire",
    key: 'CI'
  },
  {
    label: 'Cook Islands',
    value: 'Cook Islands',
    key: 'CK'
  },
  {
    label: 'Chile',
    value: 'Chile',
    key: 'CL'
  },
  {
    label: 'Cameroon',
    value: 'Cameroon',
    key: 'CM'
  },
  {
    label: 'China',
    value: 'China',
    key: 'CN'
  },
  {
    label: 'Colombia',
    value: 'Colombia',
    key: 'CO'
  },
  {
    label: 'Costa Rica',
    value: 'Costa Rica',
    key: 'CR'
  },
  {
    label: 'Cuba',
    value: 'Cuba',
    key: 'CU'
  },
  {
    label: 'Cabo Verde',
    value: 'Cabo Verde',
    key: 'CV'
  },
  {
    label: 'Curaçao',
    value: 'Curaçao',
    key: 'CW'
  },
  {
    label: 'Christmas Island',
    value: 'Christmas Island',
    key: 'CX'
  },
  {
    label: 'Cyprus',
    value: 'Cyprus',
    key: 'CY'
  },
  {
    label: 'Czechia',
    value: 'Czechia',
    key: 'CZ'
  },
  {
    label: 'Germany',
    value: 'Germany',
    key: 'DE'
  },
  {
    label: 'Djibouti',
    value: 'Djibouti',
    key: 'DJ'
  },
  {
    label: 'Denmark',
    value: 'Denmark',
    key: 'DK'
  },
  {
    label: 'Dominica',
    value: 'Dominica',
    key: 'DM'
  },
  {
    label: 'Dominican Republic',
    value: 'Dominican Republic',
    key: 'DO'
  },
  {
    label: 'Algeria',
    value: 'Algeria',
    key: 'DZ'
  },
  {
    label: 'Ecuador',
    value: 'Ecuador',
    key: 'EC'
  },
  {
    label: 'Estonia',
    value: 'Estonia',
    key: 'EE'
  },
  {
    label: 'Egypt',
    value: 'Egypt',
    key: 'EG'
  },
  {
    label: 'Western Sahara',
    value: 'Western Sahara',
    key: 'EH'
  },
  {
    label: 'Eritrea',
    value: 'Eritrea',
    key: 'ER'
  },
  {
    label: 'Spain',
    value: 'Spain',
    key: 'ES'
  },
  {
    label: 'Ethiopia',
    value: 'Ethiopia',
    key: 'ET'
  },
  {
    label: 'Finland',
    value: 'Finland',
    key: 'FI'
  },
  {
    label: 'Fiji',
    value: 'Fiji',
    key: 'FJ'
  },
  {
    label: 'Falkland Islands (Malvinas)',
    value: 'Falkland Islands (Malvinas)',
    key: 'FK'
  },
  {
    label: 'Micronesia (Federated States of)',
    value: 'Micronesia (Federated States of)',
    key: 'FM'
  },
  {
    label: 'Faroe Islands',
    value: 'Faroe Islands',
    key: 'FO'
  },
  {
    label: 'France',
    value: 'France',
    key: 'FR'
  },
  {
    label: 'Gabon',
    value: 'Gabon',
    key: 'GA'
  },
  {
    label: 'United Kingdom of Great Britain and Northern Ireland',
    value: 'United Kingdom of Great Britain and Northern Ireland',
    key: 'GB'
  },
  {
    label: 'Grenada',
    value: 'Grenada',
    key: 'GD'
  },
  {
    label: 'Georgia',
    value: 'Georgia',
    key: 'GE'
  },
  {
    label: 'French Guiana',
    value: 'French Guiana',
    key: 'GF'
  },
  {
    label: 'Guernsey',
    value: 'Guernsey',
    key: 'GG'
  },
  {
    label: 'Ghana',
    value: 'Ghana',
    key: 'GH'
  },
  {
    label: 'Gibraltar',
    value: 'Gibraltar',
    key: 'GI'
  },
  {
    label: 'Greenland',
    value: 'Greenland',
    key: 'GL'
  },
  {
    label: 'Gambia',
    value: 'Gambia',
    key: 'GM'
  },
  {
    label: 'Guinea',
    value: 'Guinea',
    key: 'GN'
  },
  {
    label: 'Guadeloupe',
    value: 'Guadeloupe',
    key: 'GP'
  },
  {
    label: 'Equatorial Guinea',
    value: 'Equatorial Guinea',
    key: 'GQ'
  },
  {
    label: 'Greece',
    value: 'Greece',
    key: 'GR'
  },
  {
    label: 'South Georgia and the South Sandwich Islands',
    value: 'South Georgia and the South Sandwich Islands',
    key: 'GS'
  },
  {
    label: 'Guatemala',
    value: 'Guatemala',
    key: 'GT'
  },
  {
    label: 'Guam',
    value: 'Guam',
    key: 'GU'
  },
  {
    label: 'Guinea-Bissau',
    value: 'Guinea-Bissau',
    key: 'GW'
  },
  {
    label: 'Guyana',
    value: 'Guyana',
    key: 'GY'
  },
  {
    label: 'Hong Kong',
    value: 'Hong Kong',
    key: 'HK'
  },
  {
    label: 'Heard Island and McDonald Islands',
    value: 'Heard Island and McDonald Islands',
    key: 'HM'
  },
  {
    label: 'Honduras',
    value: 'Honduras',
    key: 'HN'
  },
  {
    label: 'Croatia',
    value: 'Croatia',
    key: 'HR'
  },
  {
    label: 'Haiti',
    value: 'Haiti',
    key: 'HT'
  },
  {
    label: 'Hungary',
    value: 'Hungary',
    key: 'HU'
  },
  {
    label: 'Indonesia',
    value: 'Indonesia',
    key: 'ID'
  },
  {
    label: 'Ireland',
    value: 'Ireland',
    key: 'IE'
  },
  {
    label: 'Israel',
    value: 'Israel',
    key: 'IL'
  },
  {
    label: 'Isle of Man',
    value: 'Isle of Man',
    key: 'IM'
  },
  {
    label: 'India',
    value: 'India',
    key: 'IN'
  },
  {
    label: 'British Indian Ocean Territory',
    value: 'British Indian Ocean Territory',
    key: 'IO'
  },
  {
    label: 'Iraq',
    value: 'Iraq',
    key: 'IQ'
  },
  {
    label: 'Iran (Islamic Republic of)',
    value: 'Iran (Islamic Republic of)',
    key: 'IR'
  },
  {
    label: 'Iceland',
    value: 'Iceland',
    key: 'IS'
  },
  {
    label: 'Italy',
    value: 'Italy',
    key: 'IT'
  },
  {
    label: 'Jersey',
    value: 'Jersey',
    key: 'JE'
  },
  {
    label: 'Jamaica',
    value: 'Jamaica',
    key: 'JM'
  },
  {
    label: 'Jordan',
    value: 'Jordan',
    key: 'JO'
  },
  {
    label: 'Japan',
    value: 'Japan',
    key: 'JP'
  },
  {
    label: 'Kenya',
    value: 'Kenya',
    key: 'KE'
  },
  {
    label: 'Kyrgyzstan',
    value: 'Kyrgyzstan',
    key: 'KG'
  },
  {
    label: 'Cambodia',
    value: 'Cambodia',
    key: 'KH'
  },
  {
    label: 'Kiribati',
    value: 'Kiribati',
    key: 'KI'
  },
  {
    label: 'Comoros',
    value: 'Comoros',
    key: 'KM'
  },
  {
    label: 'Saint Kitts and Nevis',
    value: 'Saint Kitts and Nevis',
    key: 'KN'
  },
  {
    label: "Korea (Democratic People's Republic of)",
    value: "Korea (Democratic People's Republic of)",
    key: 'KP'
  },
  {
    label: 'Korea, Republic of',
    value: 'Korea, Republic of',
    key: 'KR'
  },
  {
    label: 'Kuwait',
    value: 'Kuwait',
    key: 'KW'
  },
  {
    label: 'Cayman Islands',
    value: 'Cayman Islands',
    key: 'KY'
  },
  {
    label: 'Kazakhstan',
    value: 'Kazakhstan',
    key: 'KZ'
  },
  {
    label: "Lao People's Democratic Republic",
    value: "Lao People's Democratic Republic",
    key: 'LA'
  },
  {
    label: 'Lebanon',
    value: 'Lebanon',
    key: 'LB'
  },
  {
    label: 'Saint Lucia',
    value: 'Saint Lucia',
    key: 'LC'
  },
  {
    label: 'Liechtenstein',
    value: 'Liechtenstein',
    key: 'LI'
  },
  {
    label: 'Sri Lanka',
    value: 'Sri Lanka',
    key: 'LK'
  },
  {
    label: 'Liberia',
    value: 'Liberia',
    key: 'LR'
  },
  {
    label: 'Lesotho',
    value: 'Lesotho',
    key: 'LS'
  },
  {
    label: 'Lithuania',
    value: 'Lithuania',
    key: 'LT'
  },
  {
    label: 'Luxembourg',
    value: 'Luxembourg',
    key: 'LU'
  },
  {
    label: 'Latvia',
    value: 'Latvia',
    key: 'LV'
  },
  {
    label: 'Libya',
    value: 'Libya',
    key: 'LY'
  },
  {
    label: 'Morocco',
    value: 'Morocco',
    key: 'MA'
  },
  {
    label: 'Monaco',
    value: 'Monaco',
    key: 'MC'
  },
  {
    label: 'Moldova, Republic of',
    value: 'Moldova, Republic of',
    key: 'MD'
  },
  {
    label: 'Montenegro',
    value: 'Montenegro',
    key: 'ME'
  },
  {
    label: 'Saint Martin (French part)',
    value: 'Saint Martin (French part)',
    key: 'MF'
  },
  {
    label: 'Madagascar',
    value: 'Madagascar',
    key: 'MG'
  },
  {
    label: 'Marshall Islands',
    value: 'Marshall Islands',
    key: 'MH'
  },
  {
    label: 'North Macedonia',
    value: 'North Macedonia',
    key: 'MK'
  },
  {
    label: 'Mali',
    value: 'Mali',
    key: 'ML'
  },
  {
    label: 'Myanmar',
    value: 'Myanmar',
    key: 'MM'
  },
  {
    label: 'Mongolia',
    value: 'Mongolia',
    key: 'MN'
  },
  {
    label: 'Macao',
    value: 'Macao',
    key: 'MO'
  },
  {
    label: 'Northern Mariana Islands',
    value: 'Northern Mariana Islands',
    key: 'MP'
  },
  {
    label: 'Martinique',
    value: 'Martinique',
    key: 'MQ'
  },
  {
    label: 'Mauritania',
    value: 'Mauritania',
    key: 'MR'
  },
  {
    label: 'Montserrat',
    value: 'Montserrat',
    key: 'MS'
  },
  {
    label: 'Malta',
    value: 'Malta',
    key: 'MT'
  },
  {
    label: 'Mauritius',
    value: 'Mauritius',
    key: 'MU'
  },
  {
    label: 'Maldives',
    value: 'Maldives',
    key: 'MV'
  },
  {
    label: 'Malawi',
    value: 'Malawi',
    key: 'MW'
  },
  {
    label: 'Mexico',
    value: 'Mexico',
    key: 'MX'
  },
  {
    label: 'Malaysia',
    value: 'Malaysia',
    key: 'MY'
  },
  {
    label: 'Mozambique',
    value: 'Mozambique',
    key: 'MZ'
  },
  {
    label: 'Namibia',
    value: 'Namibia',
    key: 'NA'
  },
  {
    label: 'New Caledonia',
    value: 'New Caledonia',
    key: 'NC'
  },
  {
    label: 'Niger',
    value: 'Niger',
    key: 'NE'
  },
  {
    label: 'Norfolk Island',
    value: 'Norfolk Island',
    key: 'NF'
  },
  {
    label: 'Nigeria',
    value: 'Nigeria',
    key: 'NG'
  },
  {
    label: 'Nicaragua',
    value: 'Nicaragua',
    key: 'NI'
  },
  {
    label: 'Netherlands',
    value: 'Netherlands',
    key: 'NL'
  },
  {
    label: 'Norway',
    value: 'Norway',
    key: 'NO'
  },
  {
    label: 'Nepal',
    value: 'Nepal',
    key: 'NP'
  },
  {
    label: 'Nauru',
    value: 'Nauru',
    key: 'NR'
  },
  {
    label: 'Niue',
    value: 'Niue',
    key: 'NU'
  },
  {
    label: 'New Zealand',
    value: 'New Zealand',
    key: 'NZ'
  },
  {
    label: 'Oman',
    value: 'Oman',
    key: 'OM'
  },
  {
    label: 'Panama',
    value: 'Panama',
    key: 'PA'
  },
  {
    label: 'Peru',
    value: 'Peru',
    key: 'PE'
  },
  {
    label: 'French Polynesia',
    value: 'French Polynesia',
    key: 'PF'
  },
  {
    label: 'Papua New Guinea',
    value: 'Papua New Guinea',
    key: 'PG'
  },
  {
    label: 'Philippines',
    value: 'Philippines',
    key: 'PH'
  },
  {
    label: 'Pakistan',
    value: 'Pakistan',
    key: 'PK'
  },
  {
    label: 'Poland',
    value: 'Poland',
    key: 'PL'
  },
  {
    label: 'Saint Pierre and Miquelon',
    value: 'Saint Pierre and Miquelon',
    key: 'PM'
  },
  {
    label: 'Pitcairn',
    value: 'Pitcairn',
    key: 'PN'
  },
  {
    label: 'Puerto Rico',
    value: 'Puerto Rico',
    key: 'PR'
  },
  {
    label: 'Palestine, State of',
    value: 'Palestine, State of',
    key: 'PS'
  },
  {
    label: 'Portugal',
    value: 'Portugal',
    key: 'PT'
  },
  {
    label: 'Palau',
    value: 'Palau',
    key: 'PW'
  },
  {
    label: 'Paraguay',
    value: 'Paraguay',
    key: 'PY'
  },
  {
    label: 'Qatar',
    value: 'Qatar',
    key: 'QA'
  },
  {
    label: 'Réunion',
    value: 'Réunion',
    key: 'RE'
  },
  {
    label: 'Romania',
    value: 'Romania',
    key: 'RO'
  },
  {
    label: 'Serbia',
    value: 'Serbia',
    key: 'RS'
  },
  {
    label: 'Russian Federation',
    value: 'Russian Federation',
    key: 'RU'
  },
  {
    label: 'Rwanda',
    value: 'Rwanda',
    key: 'RW'
  },
  {
    label: 'Saudi Arabia',
    value: 'Saudi Arabia',
    key: 'SA'
  },
  {
    label: 'Solomon Islands',
    value: 'Solomon Islands',
    key: 'SB'
  },
  {
    label: 'Seychelles',
    value: 'Seychelles',
    key: 'SC'
  },
  {
    label: 'Sudan',
    value: 'Sudan',
    key: 'SD'
  },
  {
    label: 'Sweden',
    value: 'Sweden',
    key: 'SE'
  },
  {
    label: 'Singapore',
    value: 'Singapore',
    key: 'SG'
  },
  {
    label: 'Saint Helena, Ascension and Tristan da Cunha',
    value: 'Saint Helena, Ascension and Tristan da Cunha',
    key: 'SH'
  },
  {
    label: 'Slovenia',
    value: 'Slovenia',
    key: 'SI'
  },
  {
    label: 'Svalbard and Jan Mayen',
    value: 'Svalbard and Jan Mayen',
    key: 'SJ'
  },
  {
    label: 'Slovakia',
    value: 'Slovakia',
    key: 'SK'
  },
  {
    label: 'Sierra Leone',
    value: 'Sierra Leone',
    key: 'SL'
  },
  {
    label: 'San Marino',
    value: 'San Marino',
    key: 'SM'
  },
  {
    label: 'Senegal',
    value: 'Senegal',
    key: 'SN'
  },
  {
    label: 'Somalia',
    value: 'Somalia',
    key: 'SO'
  },
  {
    label: 'Suriname',
    value: 'Suriname',
    key: 'SR'
  },
  {
    label: 'South Sudan',
    value: 'South Sudan',
    key: 'SS'
  },
  {
    label: 'Sao Tome and Principe',
    value: 'Sao Tome and Principe',
    key: 'ST'
  },
  {
    label: 'El Salvador',
    value: 'El Salvador',
    key: 'SV'
  },
  {
    label: 'Sint Maarten (Dutch part)',
    value: 'Sint Maarten (Dutch part)',
    key: 'SX'
  },
  {
    label: 'Syrian Arab Republic',
    value: 'Syrian Arab Republic',
    key: 'SY'
  },
  {
    label: 'Eswatini',
    value: 'Eswatini',
    key: 'SZ'
  },
  {
    label: 'Turks and Caicos Islands',
    value: 'Turks and Caicos Islands',
    key: 'TC'
  },
  {
    label: 'Chad',
    value: 'Chad',
    key: 'TD'
  },
  {
    label: 'French Southern Territories',
    value: 'French Southern Territories',
    key: 'TF'
  },
  {
    label: 'Togo',
    value: 'Togo',
    key: 'TG'
  },
  {
    label: 'Thailand',
    value: 'Thailand',
    key: 'TH'
  },
  {
    label: 'Tajikistan',
    value: 'Tajikistan',
    key: 'TJ'
  },
  {
    label: 'Tokelau',
    value: 'Tokelau',
    key: 'TK'
  },
  {
    label: 'Timor-Leste',
    value: 'Timor-Leste',
    key: 'TL'
  },
  {
    label: 'Turkmenistan',
    value: 'Turkmenistan',
    key: 'TM'
  },
  {
    label: 'Tunisia',
    value: 'Tunisia',
    key: 'TN'
  },
  {
    label: 'Tonga',
    value: 'Tonga',
    key: 'TO'
  },
  {
    label: 'Turkey',
    value: 'Turkey',
    key: 'TR'
  },
  {
    label: 'Trinidad and Tobago',
    value: 'Trinidad and Tobago',
    key: 'TT'
  },
  {
    label: 'Tuvalu',
    value: 'Tuvalu',
    key: 'TV'
  },
  {
    label: 'Taiwan, Province of China',
    value: 'Taiwan, Province of China',
    key: 'TW'
  },
  {
    label: 'Tanzania, United Republic of',
    value: 'Tanzania, United Republic of',
    key: 'TZ'
  },
  {
    label: 'Ukraine',
    value: 'Ukraine',
    key: 'UA'
  },
  {
    label: 'Uganda',
    value: 'Uganda',
    key: 'UG'
  },
  {
    label: 'United States Minor Outlying Islands',
    value: 'United States Minor Outlying Islands',
    key: 'UM'
  },
  {
    label: 'United States of America',
    value: 'United States of America',
    key: 'US'
  },
  {
    label: 'Uruguay',
    value: 'Uruguay',
    key: 'UY'
  },
  {
    label: 'Uzbekistan',
    value: 'Uzbekistan',
    key: 'UZ'
  },
  {
    label: 'Holy See',
    value: 'Holy See',
    key: 'VA'
  },
  {
    label: 'Saint Vincent and the Grenadines',
    value: 'Saint Vincent and the Grenadines',
    key: 'VC'
  },
  {
    label: 'Venezuela (Bolivarian Republic of)',
    value: 'Venezuela (Bolivarian Republic of)',
    key: 'VE'
  },
  {
    label: 'Virgin Islands (British)',
    value: 'Virgin Islands (British)',
    key: 'VG'
  },
  {
    label: 'Virgin Islands (U.S.)',
    value: 'Virgin Islands (U.S.)',
    key: 'VI'
  },
  {
    label: 'Viet Nam',
    value: 'Viet Nam',
    key: 'VN'
  },
  {
    label: 'Vanuatu',
    value: 'Vanuatu',
    key: 'VU'
  },
  {
    label: 'Wallis and Futuna',
    value: 'Wallis and Futuna',
    key: 'WF'
  },
  {
    label: 'Samoa',
    value: 'Samoa',
    key: 'WS'
  },
  {
    label: 'Yemen',
    value: 'Yemen',
    key: 'YE'
  },
  {
    label: 'Mayotte',
    value: 'Mayotte',
    key: 'YT'
  },
  {
    label: 'South Africa',
    value: 'South Africa',
    key: 'ZA'
  },
  {
    label: 'Zambia',
    value: 'Zambia',
    key: 'ZM'
  },
  {
    label: 'Zimbabwe',
    value: 'Zimbabwe',
    key: 'ZW'
  }
]
