export const ALERT_CONDITIONS = [
  {
    value: 'above',
    label: 'is above'
  },
  {
    value: 'below',
    label: 'is below'
  }
]

export const ALERT_FREQUENCY = [
  {
    value: 'hourly',
    label: 'Hourly'
  },
  {
    value: 'daily',
    label: 'Daily'
  },
  {
    value: 'monthly',
    label: 'Monthly'
  }
]
