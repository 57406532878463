import Cookies from 'js-cookie'

export const getHeader = (headerType) => {
  const accessToken = Cookies.get('user_token')
  let header = {}

  switch (headerType) {
    case 'json':
      header = {
        'Content-Type': 'application/json',
        Authorization: accessToken ? `Bearer ${accessToken}` : ''
      }
      break

    case 'multipart':
      header = {
        'Content-Type': 'multipart/form-data',
        Authorization: accessToken ? `Bearer ${accessToken}` : ''
      }
      break

    case 'default':
      header = {
        'Content-Type': 'application/json'
      }
      break

    default:
      header = {
        'Content-Type': 'application/json'
      }
  }

  return header
}
