import React, { Fragment } from 'react'

export default function Footer ({
  stripe,
  stripeLoading,
  onStripeBack,
  onSaveCard,
  summary,
  onBack,
  loading,
  onConfirmPlan
}) {
  return (
    <>
      <div className='modal-footer'>
        {stripe
          ? (
            <div className='flex-column gap-2 d-flex'>
              <div className='d-flex justify-content-end'>
                <button className='btn btn-white me-2' onClick={onStripeBack}>
                  <i className='tio-arrow-backward me-2' />
                  Back
                </button>
                <button
                  className='btn btn-primary col-xs-3'
                  onClick={onSaveCard}
                  disabled={stripeLoading}
                >
                  {stripeLoading ? 'Saving...' : 'Save Card'}
                </button>
              </div>
            </div>
            )
          : summary
            ? (
              <>
                <button className='btn btn-white me-2' onClick={onBack}>
                  <i className='tio-arrow-backward me-2' />
                  Back
                </button>
                <button
                  className='btn btn-primary'
                  onClick={onConfirmPlan}
                  disabled={loading}
                >
                  {loading && (
                    <span
                      className='spinner-border spinner-border-sm me-1'
                      role='status'
                      aria-hidden='true'
                    />
                  )}
                  Confirm Plan
                </button>
              </>
              )
            : (
              <></>
              )}
      </div>
    </>
  )
}
