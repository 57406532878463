export const EMBED_LOGO_POSITION = [
  {
    value: 'top-left',
    label: 'Top Left'
  },
  {
    value: 'top-right',
    label: 'Top Right'
  },
  {
    value: 'bottom-left',
    label: 'Bottom Left'
  }, {
    value: 'bottom-right',
    label: 'Bottom Right'
  }
]
