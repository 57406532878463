import * as Sentry from '@sentry/react'
import { App } from 'Pages/App'
import React from 'react'
import ReactDOM from 'react-dom'

import {
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes
} from 'react-router-dom'

import '@yaireo/tagify/dist/tagify.css' // Tagify CSS
import 'react-responsive-modal/styles.css'
import 'assets/css/select.min.css'
import 'assets/css/icon/style.css'
import 'assets/css/theme-light.css'
import 'assets/css/theme-dark.css'
import 'assets/css/uppy.css'
import 'assets/css/custom.css'

Sentry.init({
  dsn: 'https://3e03e883a3b3253d86b86a30a80e40de@o4505942694756352.ingest.sentry.io/4505942704783361',
  integrations: [
    new Sentry.reactRouterV6BrowserTracingIntegration({ // eslint-disable-line new-cap
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    })
  ],
  // Performance Monitoring
  tracePropagationTargets: ['localhost', /^https:\/\/api\.gumlet\.com/],
  tracesSampleRate: 0.1 // Capture 100% of the transactions, reduce in production!
})

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)
