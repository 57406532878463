import React, { Fragment, useContext } from 'react'
import DataProvider from 'context/DataContext'

export default function NavbarTabLayout ({
  tabData,
  activeTab,
  setActiveTab,
  type,
  tabColor,
  PublishingOptionsCard
}) {
  const { theme } = useContext(DataProvider)

  return (
    <>
      <div className='row align-items-center justify-content-start'>
        <div>
          <ul
            className={`nav nav-lg-down-break  nav-justified nav-fill nav-segment 
            ${type ? 'mb-0 ' : 'mb-md-4'}
            ${theme === 'dark' ? 'bg-light' : 'bg-soft-secondary'} 
              `}
          >
            {PublishingOptionsCard
              ? // If PublishingOptionsCard is true
              tabData.map((item, index) => {
                const handleClick = () => {
                  setActiveTab(item.id)
                }
                return (
                  <li className='nav-item' key={index}>
                    {item && item.label && (
                      <div
                        key={index}
                        onClick={handleClick}
                        id={item.id}
                        className={`text-center nav-link ${
                            activeTab === item.id
                              ? `fw-semi-bold active ${
                                  tabColor || ''
                                }`
                              : ''
                          }`}
                      >
                        {item.icon
                          ? (
                            <i
                              className={` ${item.icon}`}
                              style={{ fontSize: '1.5rem' }}
                            />
                            )
                          : (
                            <></>
                            )}
                        <div className='fw-semibold mt-n2'>
                          <small>{item.label}</small>
                        </div>
                      </div>
                    )}
                  </li>
                )
              })
              : // If PublishingOptionsCard is false
              tabData.map((item, index) => {
                const handleClick = () => {
                  setActiveTab(item.id)
                }
                return (
                  <li className='nav-item' key={index}>
                    {item && item.label && (
                      <span
                        key={index}
                        onClick={handleClick}
                        id={item.id}
                        className={`nav-link ${
                            activeTab === item.id
                              ? `fw-semi-bold active ${
                                  tabColor || ''
                                }`
                              : ''
                          }`}
                      >
                        {item.icon
                          ? (
                            <i className={` ${item.icon} me-2`} />
                            )
                          : (
                            <></>
                            )}
                        {item.label}
                      </span>
                    )}
                  </li>
                )
              })}
          </ul>
        </div>
      </div>
    </>
  )
}
