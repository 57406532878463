export const VIDEO_CODECS = [
  {
    value: 'libx264',
    label: 'H264 (AVC)'
  },
  {
    value: 'libx265',
    label: 'H265 (HEVC)'
  }
]
