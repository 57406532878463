import React, { Fragment } from 'react'
import images from '../../global/constants/images'

export default function ErrorBoundary ({
  error,
  resetError
}) {
  const refreshPage = () => {
    window.location.reload()
  }
  return (
    <>
      <div className='content container-fluid'>
        <div className='row justify-content-sm-center text-center py-10'>
          <div className='col-sm-7 col-md-5'>
            <img
              className='img-fluid mb-5'
              src={images.empty_box}
              alt='Error image-1'
              style={{ maxWidth: '60%' }}
            />
            <h1 className='display-4'>Something went wrong !</h1>
            <button className='btn btn-primary btn-lg fw-medium mt-3' onClick={() => refreshPage()}>
              Refresh Page
            </button>
          </div>
        </div>
        {/* End Row */}
      </div>
    </>
  )
}
