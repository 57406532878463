export const VIDEO_PRIVACY = [
  {
    label: 'Public',
    value: 'public'
  },
  {
    label: 'Private',
    value: 'private'
  },
  {
    label: 'Password Protected',
    value: 'password-protected'
  }
]

export const LEVEL1 = [
  {
    label: 'Direct Upload',
    value: 'direct-upload'
  },
  {
    label: 'Amazon S3 Bucket',
    value: 'aws'
  },
  {
    label: 'Google Cloud Storage',
    value: 'gcs'
  },
  {
    label: 'DigitalOcean Spaces Bucket',
    value: 'dostorage'
  },
  {
    label: 'Wasabi Storage',
    value: 'wasabi'
  },

  {
    label: 'Zoom',
    value: 'zoom'
  },
  {
    label: 'Azure Storage',
    value: 'azure'
  },
  {
    label: 'Linode Storage',
    value: 'linode'
  },
  {
    label: 'Backblaze Storage',
    value: 'backblaze'
  },
  {
    label: 'Web Folder',
    value: 'webfolder'
  },
  {
    label: 'Web Proxy',
    value: 'proxy'
  }
]

export const LEVEL2 = {
  webfolder: [
    {
      label: 'Base URL',
      type: 'input',
      input_type: 'url',
      help_text:
        'Please enter the domain name from where your videos are served. For example, if your video URLs look like <code>https://example.com/folder1/videos/uploads/sample.mp4. </code>Just enter <code>https://example.com</code>',
      tool_tip: '',
      placeholder: 'e.g https://example.com',
      key: 'webfolder.base_url'
    }
  ],
  aws: [
    {
      label: 'Bucket Name',
      type: 'input',
      input_type: 'text',
      help_text: '',
      tool_tip: '',
      placeholder: 'Bucket Name',
      key: 'aws.bucket_name',
      required: true
    },
    {
      label: 'Bucket Region',
      type: 'select',
      help_text: '',
      tool_tip: '',
      placeholder: 'Select Region',
      level: 'Second',
      key: 'aws.bucket_region',
      required: true,
      options: [
        {
          label: 'Asia Pacific (Mumbai) ap-south-1',
          value: 'ap-south-1'
        },
        {
          label: 'Asia Pacific (Seoul) ap-northeast-2',
          value: 'ap-northeast-2'
        },
        {
          label: 'Asia Pacific (Singapore) ap-southeast-1',
          value: 'ap-southeast-1'
        },
        {
          label: 'Asia Pacific (Sydney) ap-southeast-2',
          value: 'ap-southeast-2'
        },
        {
          label: 'Asia Pacific (Tokyo) ap-northeast-1',
          value: 'ap-northeast-1'
        },
        {
          label: 'Asia Pacific (Osaka) ap-northeast-3',
          value: 'ap-northeast-3'
        },
        {
          label: 'Canada (Central) ca-central-1',
          value: 'ca-central-1'
        },
        {
          label: 'EU (Frankfurt) eu-central-1',
          value: 'eu-central-1'
        },
        {
          label: 'EU (Ireland) eu-west-1',
          value: 'eu-west-1'
        },
        {
          label: 'EU (London) eu-west-2',
          value: 'eu-west-2'
        },
        {
          label: 'EU (Paris) eu-west-3',
          value: 'eu-west-3'
        },
        {
          label: 'EU (StockHolm) eu-north-1',
          value: 'eu-north-1'
        },
        {
          label: 'South America (São Paulo) sa-east-1',
          value: 'sa-east-1'
        },
        {
          label: 'US East (N. Virginia) us-east-1',
          value: 'us-east-1'
        },
        {
          label: 'US East (Ohio) us-east-2',
          value: 'us-east-2'
        },
        {
          label: 'US West (N. California) us-west-1',
          value: 'us-west-1'
        },
        {
          label: 'US West (Oregon) us-west-2',
          value: 'us-west-2'
        },
        {
          label: 'Africa (Cape Town) af-south-1',
          value: 'af-south-1'
        }
      ]
    },
    {
      label: 'Base Path',
      type: 'input',
      input_type: 'text',
      help_text: '',
      tool_tip:
        'An optional path that is prepended to video path when building request URL.',
      placeholder: 'e.g. /videos/some_path',
      key: 'aws.base_path',
      isHelp: true,
      labelHelpText:
        'An optional path that is prepended to video path when building request URL.',
      labelHelpIcon: 'bi bi-question-circle-fill',
      labelHelpLink: ''
    },
    {
      label: 'Access Key',
      type: 'input',
      input_type: 'text',
      help_text: '',
      tool_tip: '',
      placeholder: 'Access Key',
      key: 'aws.access_key',
      required: true
    },
    {
      label: 'Secret',
      type: 'input',
      input_type: 'password',
      help_text:
        '( We suggest you provide a read-only access for your bucket.)',
      tool_tip: '',
      placeholder: 'Access Secret',
      key: 'aws.secret',
      required: true
    }
  ],
  dostorage: [
    {
      label: 'Bucket Name',
      type: 'input',
      input_type: 'text',
      help_text: '',
      tool_tip: '',
      placeholder: 'Bucket Name',
      key: 'dostorage.bucket_name'
    },
    {
      label: 'Spaces Region',
      type: 'select',
      help_text: '',
      tool_tip: '',
      placeholder: 'Select Region',
      level: 'Second',
      key: 'dostorage.bucket_region',
      options: [
        {
          label: 'New York (nyc3)',
          value: 'nyc3'
        },
        {
          label: 'Amsterdam York (ams3)',
          value: 'ams3'
        },
        {
          label: 'Singapore (sgp1)',
          value: 'sgp1'
        },
        {
          label: 'Bengaluru (blr1)',
          value: 'blr1'
        },
        {
          label: 'San Francisco York (sfo2)',
          value: 'sfo2'
        },
        {
          label: 'San Francisco York (sfo3)',
          value: 'sfo3'
        },
        {
          label: 'Frankfurt (fra1)',
          value: 'fra1'
        }
      ]
    },
    {
      label: 'Base Path',
      type: 'input',
      input_type: 'text',
      help_text: '',
      tool_tip:
        'An optional path that is prepended to video path when building request URL.',
      placeholder: 'e.g. /videos/some_path',
      key: 'dostorage.base_path',
      isHelp: true,
      labelHelpText:
        'An optional path that is prepended to video path when building request URL.',
      labelHelpIcon: 'bi bi-question-circle-fill',
      labelHelpLink: ''
    },
    {
      label: 'Access Key',
      type: 'input',
      help_text: '',
      tool_tip: '',
      placeholder: 'Access Key',
      key: 'dostorage.access_key'
    },
    {
      label: 'Secret',
      type: 'input',
      input_type: 'password',
      help_text:
        '( We suggest you provide a read-only access for your bucket.)',
      tool_tip: '',
      placeholder: 'Access Secret',
      key: 'dostorage.secret'
    }
  ],
  wasabi: [
    {
      label: 'Bucket Name',
      type: 'input',
      input_type: 'text',
      help_text: '',
      tool_tip: '',
      placeholder: 'Bucket Name',
      key: 'wasabi.bucket_name'
    },
    {
      label: 'Wasabi Region',
      type: 'select',
      help_text: '',
      tool_tip: '',
      placeholder: 'Select Region',
      level: 'Second',
      key: 'wasabi.bucket_region',
      options: [
        {
          label: 'Oregon (us-west-1)',
          value: 'us-west-1'
        },
        {
          label: 'North Virginia (us-east-1)',
          value: 'us-east-1'
        },
        {
          label: 'North Virginia (us-east-2)',
          value: 'us-east-2'
        },
        {
          label: 'Amsterdam (eu-central-1)',
          value: 'eu-central-1'
        },
        {
          label: 'Singapore (ap-southeast-1)',
          value: 'ap-southeast-1'
        },
        {
          label: 'Plano, TX (us-central-1)',
          value: 'us-central-1'
        },
        {
          label: 'Toronto (ca-central-1)',
          value: 'ca-central-1'
        },
        {
          label: 'London (eu-west-1)',
          value: 'eu-west-1'
        },
        {
          label: 'Paris (eu-west-2)',
          value: 'eu-west-2'
        },
        {
          label: 'Frankfurt (eu-central-2)',
          value: 'eu-central-2'
        },
        {
          label: 'Tokyo (ap-northeast-1)',
          value: 'ap-northeast-1'
        },
        {
          label: 'Osaka (ap-northeast-2)',
          value: 'ap-northeast-2'
        },
        {
          label: 'Sydney (ap-southeast-2)',
          value: 'ap-southeast-2'
        }
      ]
    },
    {
      label: 'Base Path',
      type: 'input',
      input_type: 'text',
      help_text: '',
      tool_tip:
        'An optional path that is prepended to video path when building request URL.',
      placeholder: 'e.g. /videos/some_path',
      key: 'wasabi.base_path',
      isHelp: true,
      labelHelpText:
        'An optional path that is prepended to video path when building request URL.',
      labelHelpIcon: 'bi bi-question-circle-fill',
      labelHelpLink: ''
    },
    {
      label: 'Access Key',
      type: 'input',
      help_text: '',
      tool_tip: '',
      placeholder: 'Access Key',
      key: 'wasabi.access_key'
    },
    {
      label: 'Secret',
      type: 'input',
      input_type: 'password',
      help_text:
        '( We suggest you provide a read-only access for your bucket.)',
      tool_tip: '',
      placeholder: 'Access Secret',
      key: 'wasabi.secret'
    }
  ],
  gcs: [
    {
      label: 'Bucket Name',
      type: 'input',
      input_type: 'text',
      help_text: '',
      tool_tip: '',
      placeholder: 'Bucket Name',
      key: 'gcs.bucket_name'
    },
    {
      label: 'GCS Service Account JSON Key',
      type: 'textfield',
      help_text:
        "(You need to provide 'Storage Object Viewer' role to a service account and provide us JSON key for the same.)",
      tool_tip: '',
      placeholder: 'GCS Service Account JSON Key',
      key: 'gcs.service_account_key'
    }
  ],
  zoom: [
    {
      label: 'Secret',
      type: 'input',
      input_type: 'password',
      help_text: '',
      tool_tip: '',
      placeholder: 'Access Secret',
      key: 'zoom.secret'
    }
  ],
  azure: [
    {
      label: 'Azure Blob Name',
      type: 'input',
      input_type: 'text',
      help_text: '',
      tool_tip: '',
      placeholder: 'Azure blob Name',
      key: 'azure.azure_account_name'
    },
    {
      label: 'Azure Container Name',
      type: 'input',
      input_type: 'text',
      help_text: '',
      tool_tip: '',
      placeholder: 'Container Name',
      key: 'azure.azure_container_name'
    },
    {
      label: 'Azure Shared Name',
      type: 'input',
      input_type: 'text',
      help_text: '',
      tool_tip: '',
      placeholder: 'Shared Token',
      key: 'azure.azure_shared_token'
    },
    {
      label: 'Azure Base Path',
      type: 'input',
      help_text: '',
      tool_tip: '',
      placeholder: 'Base Path',
      key: 'azure.azure_path',
      isHelp: true,
      labelHelpText:
        'An optional path that is prepended to video path when building request URL.',
      labelHelpIcon: 'bi bi-question-circle-fill',
      labelHelpLink: ''
    }
  ],
  linode: [
    {
      label: 'Bucket Name',
      type: 'input',
      input_type: 'text',
      help_text: '',
      tool_tip: '',
      placeholder: 'Bucket Name',
      key: 'linode.bucket_name'
    },
    {
      label: 'Linode Region',
      type: 'select',
      help_text: '',
      tool_tip: '',
      placeholder: 'Select Region',
      level: 'Second',
      key: 'linode.bucket_region',
      options: [
        {
          label: 'Singapore, SG',
          value: 'ap-south-1'
        },
        {
          label: 'Newark, NJ',
          value: 'us-east-1'
        },
        {
          label: 'Atlanta, GA',
          value: 'us-southeast-1'
        },
        {
          label: 'Frankfurt, DE',
          value: 'eu-central-1'
        }
      ]
    },
    {
      label: 'Access Key',
      type: 'input',
      input_type: 'text',
      help_text: '',
      tool_tip: '',
      placeholder: 'Access Key',
      key: 'linode.access_key'
    },
    {
      label: 'Secret',
      type: 'input',
      input_type: 'password',
      help_text:
        '( We suggest you provide a read-only access for your bucket.)',
      tool_tip: '',
      placeholder: 'Access Secret',
      key: 'linode.secret'
    }
  ],
  backblaze: [
    {
      label: 'Bucket Name',
      type: 'input',
      input_type: 'text',
      help_text: '',
      tool_tip: '',
      placeholder: 'Bucket Name',
      key: 'backblaze.bucket_name'
    },
    {
      label: 'Endpoint',
      type: 'input',
      help_text: '',
      tool_tip: '',
      placeholder: 'Add endpoint',
      level: 'Second',
      key: 'backblaze.endpoint'
    },
    // {
    //   label: "Backblaze Region",
    //   type: "select",
    //   help_text: "",
    //   tool_tip: "",
    //   placeholder: "Select Region",
    //   level: "Second",
    //   key: "backblaze.bucket_region",
    //   options: [
    //     {
    //       label: "us-west-001",
    //       value: "us-west-001",
    //     },
    //     {
    //       label: "us-west-002",
    //       value: "us-west-002",
    //     },
    //     {
    //       label: "us-west-003",
    //       value: "us-west-003",
    //     },
    //     {
    //       label: "us-west-004",
    //       value: "us-west-004",
    //     },
    //     {
    //       label: "us-west-005",
    //       value: "us-west-005",
    //     },
    //   ],
    // },
    {
      label: 'Base Path',
      type: 'input',
      input_type: 'text',
      help_text: '',
      tool_tip:
        'An optional path that is prepended to video path when building request URL.',
      placeholder: 'e.g. /videos/some_path',
      key: 'backblaze.base_path',
      isHelp: true,
      labelHelpText:
        'An optional path that is prepended to video path when building request URL.',
      labelHelpIcon: 'bi bi-question-circle-fill',
      labelHelpLink: ''
    },
    {
      label: 'Access Key',
      type: 'input',
      help_text: '',
      tool_tip: '',
      placeholder: 'Access Key',
      key: 'backblaze.access_key'
    },
    {
      label: 'Secret',
      type: 'input',
      input_type: 'password',
      help_text:
        '( We suggest you provide a read-only access for your bucket.)',
      tool_tip: '',
      placeholder: 'Access Secret',
      key: 'backblaze.secret'
    }
  ]
}
