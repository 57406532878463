import Upgrade from 'components/Upgrade'
import { routePaths } from 'global/routePaths'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import {
  getImageAnalyticsData,
  getVideoAnalyticsData
} from 'utils/commonApiCalls'
import { formatBytes1000 } from 'utils/helper'
import { convertAndCalculatePercentage } from '../../utils/helper'
import toast from 'react-hot-toast'
import { customTrack } from '../../utils/constants'

export default function UpgradeNudge ({ orgData, theme }) {
  const [loading, setLoading] = useState(false)
  const [bandwidthConsumption, setBandwidthConsumption] = useState('')
  const [videoBandwidthConsumption, setVideoBandwidthConsumption] =
    useState('')

  useEffect(() => {
    async function fetchImageData () {
      setLoading(true)
      try {
        const bandwidthData = await getImageAnalyticsData()
        setBandwidthConsumption(bandwidthData)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        toast.error(error?.response?.data?.error?.message)
      }
    }
    async function fetchVideoData () {
      setLoading(true)
      try {
        const bandwidth_consume = await getVideoAnalyticsData()
        setVideoBandwidthConsumption(bandwidth_consume)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        toast.error(error?.response?.data?.error?.message)
      }
    }

    fetchImageData()
    fetchVideoData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const calculateProgressBarWidth = (dataSize, quotaSize) => {
    if (quotaSize === 0 || isNaN(quotaSize)) {
      return 0 // Handle division by zero or undefined quotaSize
    }

    const progress = (dataSize / quotaSize) * 100
    return Math.round(progress)
  }

  const getImageBandwidthPercentage = () => {
    return convertAndCalculatePercentage(
      bandwidthConsumption,
      formatBytes1000(orgData?.plan_data?.image?.quota.bandwidth, 1)
    )
  }

  const getVideoBandwidthPercentage = () => {
    // Streaming usage //
    return convertAndCalculatePercentage(
      videoBandwidthConsumption,
      formatBytes1000(orgData?.plan_data?.video?.quota.bandwidth, 1)
    )
  }

  const getVideoStoragePercentage = () => {
    const storageUsage = orgData?.plan_data?.video?.usage.storage
    const storageQuota = orgData?.plan_data?.video?.quota.storage

    if (
      storageUsage === undefined ||
      storageQuota === undefined ||
      storageQuota === 0 ||
      isNaN(storageQuota)
    ) {
      return 0 // Handle undefined or zero quota values
    }

    // Storage minutes
    return calculateProgressBarWidth(
      Math.ceil(storageUsage / 60),
      Math.floor(storageQuota / 60)
    )
  }

  const getLargerPercentage = () => {
    const imageBandwidthPercentage = getImageBandwidthPercentage()
    const videoBandwidthPercentage = getVideoBandwidthPercentage()
    const videoStoragePercentage = getVideoStoragePercentage()

    let percentage
    let label

    if (
      videoStoragePercentage >= videoBandwidthPercentage &&
      videoStoragePercentage >= imageBandwidthPercentage
    ) {
      percentage = videoStoragePercentage
      label = 'Video Storage Used'
    } else if (videoBandwidthPercentage >= imageBandwidthPercentage) {
      percentage = videoBandwidthPercentage
      label = 'Video Bandwidth Used'
    } else {
      percentage = imageBandwidthPercentage
      label = 'Image Bandwidth Used'
    }

    return { label, percentage }
  }

  const getCTA = () => {
    const percentage = getLargerPercentage().percentage

    // get current plan type for pricing modal
    const getPlanType =
      getLargerPercentage().label === 'Image Bandwidth Used'
        ? 'image'
        : 'video'

    if (percentage > 50 && orgData?.plan_data?.video?.plan !== 'videoPlan5') {
      return (
        <Upgrade
          customClass='btn btn-primary btn-xs fs-8 btn-block'
          plan={getPlanType}
          from='nudge'
          onClick={() =>
            customTrack('Upgrade button clicked', {
              url: window.location.href,
              progress: getLargerPercentage().percentage,
              heading: getLargerPercentage().label
            })}
        />
      )
    } else {
      return <></>
    }
  }

  const getStatusBarColor = () => {
    const { label, percentage } = getLargerPercentage()

    // for business plan - always show current usage in primary color.
    // no danger, warning.
    if (
      (label === 'Video Bandwidth Used' || label === 'Video Storage Used') &&
      orgData?.plan_data?.video?.plan === 'videoPlan5'
    ) {
      return '#7565f7' // primary
    } else if (
      label === 'Image Bandwidth Used' &&
      orgData?.plan_data?.image?.plan === 'imagePlan3'
    ) {
      return '#7565f7' // primary
    } else if (percentage >= 80) {
      return '#dc3545' // danger
    } else if (percentage >= 50) {
      return '#ffc107' // warning
    } else {
      return '#7565f7' // primary
    }
  }

  const content = (
    <div
      className='navbar-vertical-footer'
      style={{
        bottom: '62px',
        background: theme === 'dark' ? '#191C24' : '#ffffff'
      }}
    >
      {loading
        ? (
          <div className='px-3'>
            <span className='placeholder col-12' />
            <span className='placeholder col-12' />
          </div>
          )
        : (
          <div className='px-3'>
            <div className='d-flex align-items-center'>
              <div className='col'>
                <span className='d-block fs-7'>
                  {getLargerPercentage().percentage === 0
                    ? 'Video Storage Used'
                    : getLargerPercentage().label}
                </span>
              </div>
              <div className='col-auto'>
                <span className='text-dark fs-7'>
                  {Math.ceil(getLargerPercentage().percentage)}%
                </span>
              </div>
            </div>

            <div className='progress rounded-pill mt-2 d-flex p-0'>
              <div
                className='progress-bar'
                style={{
                  width: Math.ceil(getLargerPercentage().percentage) + '%',
                  backgroundColor: getStatusBarColor()
                }}
              />
            </div>

            <div className='row mt-3 px-2'>{getCTA()}</div>
          </div>
          )}
    </div>
  )

  return getLargerPercentage().percentage < 50 ||
    orgData?.plan_data?.video?.plan === 'videoPlan5' ||
    orgData?.plan_data?.image?.plan === 'imagePlan3'
    ? (
      <NavLink
        to={routePaths.organization.billing.base}
        style={{
          color: theme !== 'dark' ? '#191C24' : '#ffffff'
        }}
        onClick={() =>
          customTrack('Progress bar clicked', {
            url: window.location.href,
            progress: getLargerPercentage().percentage,
            heading: getLargerPercentage().label
          })}
      >
        {content}
      </NavLink>
      )
    : (
        content
      )
}
