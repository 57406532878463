import { routePaths } from 'global/routePaths'

export const VIDEO_ALERT_MENU = [
  {
    name: 'Alert Details',
    icon: 'bi bi-bell',
    activeLabel: 'Video Alert',
    id: 'alert_details',
    item_route: routePaths.video.alerts.editalert
  },
  {
    name: 'Action',
    icon: 'bi bi-trash3',
    activeLabel: 'Alert Delete',
    id: 'delete',
    item_route: routePaths.video.alerts.deletealert
  }

]
