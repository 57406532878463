import React, { useContext } from 'react'
import DataProvider from 'context/DataContext'
import { ArrowBarRight, ArrowBarLeft } from 'react-bootstrap-icons'

const Header = () => {
  const { theme, onSidebarClick, setOnSidebarClick } = useContext(DataProvider)

  return (
    <header
      id='header'
      className='navbar navbar-expand-lg navbar-height navbar-flush navbar-container navbar-bordered d-xl-none'
    >
      <div className='navbar-nav-wrap'>
        <div className='navbar-brand-wrapper'>
          <a className='navbar-brand' href='/'>
            {theme === 'dark'
              ? (
                <img
                  className='navbar-brand-logo'
                  src='https://assets.gumlet.io/assets/gumlet-logo-white-font.png?w=240&format=auto'
                  alt='Logo'
                />
                )
              : (
                <img
                  className='navbar-brand-logo'
                  src='https://assets.gumlet.io/assets/gumlet-logo-black-font.png?w=240&format=auto'
                  alt='Logo'
                />
                )}

            <img
              className='navbar-brand-logo-mini'
              src='https://assets.gumlet.io/assets/round-logo.png?w=100&format=auto'
              alt='Logo'
            />
          </a>
        </div>
        <div className='navbar-nav-wrap-content-left'>
          <button
            type='button'
            className='border js-navbar-vertical-aside-toggle-invoker navbar-aside-toggler'
            style={{ opacity: '1' }}
            onClick={() => setOnSidebarClick(!onSidebarClick)}
          >
            {!onSidebarClick
              ? (
                <ArrowBarRight className='mb-1' />
                )
              : (
                <ArrowBarLeft className='mb-1' />
                )}
          </button>
        </div>
      </div>
    </header>
  )
}

export default Header
