export const VIDEO_OUTPUT_RESOLUTIONS = [
  {
    value: '240p',
    label: '240p'
  },
  {
    value: '360p',
    label: '360p'
  },
  {
    value: '480p',
    label: '480p'
  },
  {
    value: '540p',
    label: '540p'
  },
  {
    value: '720p',
    label: '720p'
  },
  {
    value: '1080p',
    label: '1080p'
  },
  {
    value: '1440p',
    label: '1440p (2k)'
  },
  {
    value: '2160p',
    label: '2160p (4k)'
  }
]
