import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { NavLink } from 'react-router-dom'
import { routePaths } from '../../../global/routePaths'

export default function ExtraHelpLinks () {
  return (
    <>
      <div className='navbar-vertical-footer-list'>
        <div className='navbar-vertical-footer-list-item'>
          <a
            className='js-hs-unfold-invoker btn btn-icon btn-ghost-secondary rounded-circle'
            href='/help'
            title='Community Support'
            data-tip
            data-for='sidebar-community-help'
            rel='noreferrer'
          >
            <i className='tio-support-vs' />
          </a>
        </div>
        <div className='navbar-vertical-footer-list-item'>
          <a
            className='js-hs-unfold-invoker btn btn-icon btn-ghost-secondary rounded-circle'
            href='https://webapp.gumlet.com/dashboard/canny-redirect/'
            target='_blank'
            title='Make a Suggestion'
            data-tip
            data-for='sidebar-suggestion'
            rel='noreferrer'
          >
            <i className='tio-chat' />
          </a>
        </div>
        <div className='navbar-vertical-footer-list-item'>
          <NavLink
            to={routePaths.review}
            className='js-hs-unfold-invoker btn btn-icon btn-ghost-secondary rounded-circle'
            data-tip
            data-for='sidebar-rewiew'
          >
            <i className='tio-quotes' />
          </NavLink>
        </div>
        <ReactTooltip id='sidebar-community-help' place='left' effect='solid'>
          Get Help
        </ReactTooltip>
        <ReactTooltip id='sidebar-suggestion' place='left' effect='solid'>
          Make a Suggestion
        </ReactTooltip>
        <ReactTooltip id='sidebar-rewiew' place='top' effect='solid'>
          Write a Review
        </ReactTooltip>
      </div>
    </>
  )
}
