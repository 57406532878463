import { routePaths } from 'global/routePaths'

export const IMAGE_ALERT_MENU = [
  {
    name: 'Alert Details',
    icon: 'bi bi-bell',
    activeLabel: 'Image Alert',
    id: 'alert_details',
    item_route: routePaths.image.alerts.editalert
  },
  {
    name: 'Action',
    icon: 'bi bi-trash3',
    activeLabel: 'Alert Delete',
    id: 'delete',
    item_route: routePaths.image.alerts.deletealert
  }
]
