import axios from 'axios'
import { getHeader } from 'utils/header'
import { config } from 'global/config'
import toast from 'react-hot-toast'

// Default API will be your root
const API_ROOT = config.API_URL
const TIMEOUT = 60000

const http = (headerType = 'json', baseURL = API_ROOT, timeout = TIMEOUT) => {
  const headers = getHeader(headerType)

  const client = axios.create({
    baseURL,
    timeout,
    headers
  })

  // Intercept response object and handleSuccess and Error Object
  client.interceptors.response.use(handleSuccess, handleError)

  function handleSuccess (response) {
    return response
  }

  /** Intercept any unauthorized request.
   * status 401 means either accessToken is expired or invalid
   * dispatch logout action accordingly **/
  function handleError (error) {
    if (error?.response?.status !== 500) {
      return Promise.reject(error?.response?.data)
    } else if (error?.response?.data?.error?.code === 'invalid_api_key') {
      toast.error(error?.response?.data?.error?.message)
      window.localStorage.removeItem('property')
      window.localStorage.removeItem('filters')
      window.localStorage.removeItem('page_width')
      window.localStorage.removeItem('last_visited_collection')
      window.location.href = config.WEBAPP_NONWWW_HOST + '/dashboard/logout/'
    } else {
      return Promise.reject(error)
    }
  }

  function get (path) {
    return client.get(path).then((response) => response.data)
  }

  function getResponse (path) {
    return client.get(path).then((response) => response)
  }

  function post (path, payload) {
    return client.post(path, payload).then((response) => response.data)
  }

  function put (path, payload) {
    return client.put(path, payload).then((response) => response.data)
  }

  function patch (path, payload) {
    return client.patch(path, payload).then((response) => response.data)
  }

  function _delete (path, data) {
    if (data) {
      return client
        .delete(path, { data })
        .then((response) => response?.data)
    }
    return client.delete(path).then((response) => response.data)
  }

  return {
    get,
    getResponse,
    post,
    put,
    patch,
    delete: _delete
  }
}

export default http
