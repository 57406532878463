const prod = {
  API_URL: 'https://api.gumlet.com/v1',
  COPY_EMBED_HOST: 'https://player-api.gumlet.io',
  EMBED_HOST: 'https://play.gumlet.io',
  EMBED_HOST_V2: 'https://play-v2.gumlet.io',
  THEME_URL: 'https://www.gumlet.com',
  WEBAPP_HOST: 'https://www.gumlet.com',
  WEBAPP_NONWWW_HOST: 'https://webapp.gumlet.com',
  STRIPE_KEY:
    'pk_live_51IMYSEDkQgpggoCG9EoQspp5pbHcVTMMcI2TG0swbGFGCHy6Kc6EqUxFsOnas99nfxDLLqpNAVbgH63AtOMr5XYb00plFrlr9M',
  STRIPE_KEY_IND: 'pk_live_3H1YLBzkOjAIrqUfWuy9FvRe00ytQn2xne',
  API_URL_UPPY: 'https://api.gumlet.com',
  INTERCOM_APP_ID: 'mmvvsd7x',
  GD_SCOPE: 'https://www.googleapis.com/auth/drive.file',
  GD_CLIENT_ID:
    '434770351425-ki13s4dfo6qh8n3s9j2hhn5ddmcqmfs4.apps.googleusercontent.com',
  GD_API_KEY: 'AIzaSyAbXeDzlDq_80nyofqajdu_L2Gh-Mqqj5k',
  GD_APP_ID: '434770351425',
  SOCKET_URL: 'https://companion.gumlet.com'
}

const dev = {
  API_URL: 'http://127.0.0.1:9000/v1',
  COPY_EMBED_HOST: 'http://localhost:8080',
  EMBED_HOST: 'http://localhost:8080',
  EMBED_HOST_V2: 'https://play-v2.gumlet.io',
  THEME_URL: 'http://localhost:3000',
  WEBAPP_HOST: 'http://localhost:8000',
  WEBAPP_NONWWW_HOST: 'http://localhost:8000',
  STRIPE_KEY:
    'pk_test_51IMYSEDkQgpggoCG3QKaY99d6daiyglJNBinbJrSeDhpcggJ8E5SgTt0YzYLwuGmZWd0Fx0hwJKEDdc0VFz89x9200e4S6KCnX',
  STRIPE_KEY_IND: 'pk_test_Yf57tgyczH49JhkYHt8U37Fp00wq3Wbt8G',
  API_URL_UPPY: 'http://127.0.0.1:9000',
  INTERCOM_APP_ID: 'hy657oni',
  GD_SCOPE: 'https://www.googleapis.com/auth/drive.file',
  GD_CLIENT_ID:
    '232184930681-g4t9r57q36djeevq2os2lmjlpd64b6d3.apps.googleusercontent.com',
  GD_API_KEY: 'AIzaSyBC3jGHOd9Cmjo3zeCCbDxxjtflIRSiUQM',
  GD_APP_ID: '232184930681',
  SOCKET_URL: 'http://localhost:8080'
}

export const config = import.meta.env.PROD ? prod : dev
