import Cookies from 'js-cookie'
import { config } from 'global/config'

export function useLogout () {
  const handleLogout = () => {
    if (!window.location.href.includes('logout')) {
      // only save non-logout path to cookie
      Cookies.set('redirect_to', window.location.href, {
        expires: 600 * 1000, // 10 min
        httpOnly: false, // http only, prevents JavaScript cookie access
        secure: !!import.meta.env.PROD, // cookie must be sent over https / ssl
        domain: import.meta.env.PROD ? '.gumlet.com' : 'localhost'
      })
    }
    // dummy comment added.
    if (typeof window.Intercom !== 'undefined') {
      window.Intercom('shutdown')
    }

    window.localStorage.removeItem('property')
    window.localStorage.removeItem('filters')
    window.localStorage.removeItem('page_width')
    window.localStorage.removeItem('last_visited_collection')
    window.localStorage.removeItem('ajs_anonymous_id')

    if (typeof window.gumletSegment !== 'undefined') {
      window.gumletSegment.sessionReset()
    }

    window.location.href = config.WEBAPP_NONWWW_HOST + '/dashboard/logout/'
  }

  return handleLogout
}
