import React from 'react'

export default function DowngradeModal ({
  setOpenDowngradeModal,
  ConfirmDowngradePlan,
  plan,
  planType,
  orgData,
  onCloseModal,
  videoFree
}) {
  const openChat = () => {
    setOpenDowngradeModal(false)
    onCloseModal()
    window.Intercom('show')
  }

  return (
    <div className='modal-dialog'>
      <div className='modal-content'>
        <div className='modal-header'>
          <h4 className='modal-title'>Do you want to lose all the content?</h4>
          <button
            className='btn btn-xs btn-icon btn-ghost-danger rounded-circle'
            onClick={() => setOpenDowngradeModal(false)}
          >
            <i className='bi bi-x-lg' />
          </button>
        </div>
        <div className='modal-body'>
          {!videoFree
            ? (
              <span className='fs-5'>
                You are about to downgrade the plan to <b>{plan.plan}</b>. You
                will lose the content stored on the{' '}
                <b>{orgData && orgData.plan_data[planType].display_name}</b> plan.
                If you are facing any issues, reach out to{' '}
                <span className='text-primary' type='button' onClick={openChat}>
                  chat support.
                </span>
              </span>
              )
            : (
              <span className='fs-5'>
                You are about to downgrade the plan to <b>Free</b>. You will lose
                the content stored on the{' '}
                <b>{orgData && orgData.plan_data[planType].display_name}</b> plan.
                If you are facing any issues, reach out to{' '}
                <span className='text-primary' type='button' onClick={openChat}>
                  chat support.
                </span>
              </span>
              )}
        </div>
        <div className='modal-footer'>
          <div className='d-flex align-items-center justify-content-end gap-2'>
            <button
              className='btn btn-sm btn-white'
              onClick={() => ConfirmDowngradePlan()}
            >
              Downgrade
            </button>
            <button
              className='btn btn-sm btn-primary d-flex align-items-center'
              onClick={() => setOpenDowngradeModal(false)}
            >
              Save my content
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
